import React from "react"
import { Link } from "gatsby"
import GRAPHIC from "../../../images/specials/hero/Oct24-Badge.svg"
import "./SpecialsHero.css"

function SpecialsHeroContent({ setShowSignUpModal }) {
  return (
    <div id="specials-hero" className="position-relative pt-0">
      <div className="container py-lg-milan-50 position-relative">
        <div className="row">
          <div className="offset-lg-6 col-lg-6 pt-4">
            {/* only hide on md */}
            <figure className="pb-4 position-relative z-index-100 text-center d-none d-lg-block">
              <img id="graphic" src={GRAPHIC} alt="Milan Laser Social" />
            </figure>
            <h1 className="milan-text-primary smalltitle text-center">
              Laser Hair Removal Specials
            </h1>
            <p>
              Hey, boo! It’s time to ghost your razor, trick unwanted hair, and
              treat yourself to 50% OFF unlimited laser hair removal plus 1
              select body area FREE* — no bones about it. Our{" "}
              <Link to="#guarantee" className="milan-text-primary">
                exclusive Unlimited Package™
              </Link>{" "}
              comes with every purchase and includes FREE touch-ups, so you’re
              covered for life at our 380+ locations nationwide.
            </p>
            {/* Desktop Only */}
            <p className="d-none d-md-block">
              <strong>Scary Not Hairy Bonus:</strong> Receive a{" "}
              <strong>FREE TREATMENT*</strong> when you complete a complimentary
              consultation this month.
            </p>
            {/* Mobile Only */}
            <p className="d-md-none">
              <strong>Scary Not Hairy Bonus:</strong> Receive a{" "}
              <strong>FREE TREATMENT*</strong> when you complete a complimentary
              consultation this month.
            </p>
            <p>
              Grab your skeleton crew and visit your{" "}
              <Link to="/locations/" className="milan-text-primary">
                local Milan Laser
              </Link>{" "}
              by <strong>October 31</strong> to get half-off unlimited laser
              hair removal and 1 FREE body area!
            </p>
            <div className="my-4 text-center">
              <Link className="milan-btn milan-cta-btn" to="#consult">
                Book My Free Consult
              </Link>
            </div>
            <p className="mb-lg-0 milan-fp">
              <span
                className="text-decoration-underline pointer"
                onClick={() => setShowSignUpModal(true)}
              >
                <strong>
                  Stay up to date with special discounts and sales by
                  subscribing to our email list.
                </strong>
              </span>
              <span>
                {" "}
                We promise we'll never spam you or sell your information.
              </span>
            </p>
            <p className="mb-lg-0 mt-2 milan-fp">
              *Call or visit clinic for details.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpecialsHeroContent

import React from 'react';
import { Link } from 'gatsby';
import logo from '../../images/military/military-logo.webp';

export function MilitaryMontel() {
  return (
    <section className='py-milan-50 py-lg-milan-100 py-xxl-milan-150 milan-bg-white'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <h2 className='subheadsm milan-text-primary'>As Seen on</h2>
            <h1 className='milan-text-primary'>
              Military Makeover with Montel<sup>&reg;</sup>
            </h1>

            <p>
              When the Milan Laser Hair Removal team heard about the Mahaley
              family and their interest in laser hair removal, we wanted to help
              however we could.
            </p>
            <p>
              The Milan Laser team members, including Chief Medical Officer, Dr.
              Nathan Haecker, had the opportunity to travel to Mooresville, NC,
              to help remodel the family’s home. In addition to helping Milan
              Laser clients get the safest treatments possible, Dr. Haecker is
              also a veteran passionate about home renovation and giving back to
              military families.
            </p>
            <p className='pb-4'>
              Milan Laser, in partnership with Military Makeover with Montel®,
              offers life-changing services. In addition to a home makeover, the
              Mahaley family received free{' '}
              <Link to='/specials/#guarantee' className='milan-text-primary'>
                unlimited laser hair removal for life
              </Link>{' '}
              as a thank-you for their sacrifice and service.
            </p>
            <div className='d-none d-lg-block'>
              <h3 className='subpointsm text-uppercase'>
                Tune In & Watch the Mahaley Family's Story
              </h3>
              <p className='p-subhead text-uppercase milan-text-primary'>
                September 6
              </p>
              <p className='p-subhead text-uppercase milan-text-primary'>
                7:30 a.m. CT
              </p>
            </div>
          </div>
          <div className='col-12 col-lg-6 d-flex align-items-center justify-content-center'>
            <div className='row d-flex align-items-center justify-content-center'>
              <div className='col-12 col-sm-6 col-lg-12'>
                <img
                  src={logo}
                  style={{ width: '100%', maxWidth: '468px', height: 'auto' }}
                  className='py-5'
                  alt='Milan Laser Hair Removal on Military Makeover with Montel'
                />
              </div>
              <div className='col-12 col-sm-6 d-lg-none'>
                <div className='ps-4'>
                  <h3
                    className='subpointsm text-uppercase mb-2'
                    style={{ fontSize: '32px', lineHeight: '2rem' }}
                  >
                    Tune In & Watch the Mahaley Family's Story
                  </h3>
                  <p className='p-subhead text-uppercase milan-text-primary'>
                    September 6
                  </p>
                  <p className='p-subhead text-uppercase milan-text-primary'>
                    7:30 a.m. CT
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MilitaryMontel;

import React, { useContext } from "react";
import { GlobalSiteDataContext } from '../../context/GlobalContextProvider'
import BADGE from "../../images/careers/BairdHolm.webp"

export function CareersAboutMilan() {
  const siteData = useContext(GlobalSiteDataContext);
  return (
    <section className="about-milan-bg milan-py">
      <div className="container text-white">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <h2 className="text-center pb-3 pb-5-sm subheadlg">About Milan</h2>
            <p>
              When you work at Milan Laser Hair Removal, you don’t just have a job, you have a career. As the largest laser hair removal company in the
              nation, we take pride in offering opportunities across multiple disciplines, with room to grow. And, we do it in a fun, inclusive, and
              supportive environment. Whether you work in our customer care center, our headquarters, or at one of our{" "}
              {siteData.milanOpenStores.countToDisplay}+ clinics across the country, you can count on working alongside the best of the best in the
              industry.
            </p>
          </div>
          <div className="col-lg-3">
            <figure className="text-center">
              <img className="mx-auto" src={BADGE} style={{ maxWidth: "250px" }} alt="Best Places to Work in Omaha" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CareersAboutMilan;
